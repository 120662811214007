var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading',{attrs:{"active":_vm.isLoadingRegistrantLists,"loader":"bars","color":"#7367f0","is-full-page":_vm.fullPage}}),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-card',{attrs:{"title":("Applicants (" + _vm.registrantTotal + ")")}},[_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Search")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Search","type":"text"},model:{value:(_vm.registrantSearchTerm),callback:function ($$v) {_vm.registrantSearchTerm=$$v},expression:"registrantSearchTerm"}})],1)])],1),_c('vue-good-table',{attrs:{"columns":_vm.registrantColumns,"rows":_vm.registrantRows,"style-class":"vgt-table striped","sort-options":{
            enabled: false,
          },"search-options":{
            enabled: true,
            externalQuery: _vm.registrantSearchTerm },"pagination-options":{
            enabled: true,
            perPageDropdownEnabled: false,
            dropdownAllowAll: false,
          }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-card',{attrs:{"title":("Approved Applicants (" + _vm.approvedRegistrantTotal + ")")}},[_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Search")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Search","type":"text"},model:{value:(_vm.approvedRegistrantSearchTerm),callback:function ($$v) {_vm.approvedRegistrantSearchTerm=$$v},expression:"approvedRegistrantSearchTerm"}})],1)])],1),_c('vue-good-table',{attrs:{"columns":_vm.approvedRegistrantColumns,"rows":_vm.approvedRegistrantRows,"style-class":"vgt-table striped","sort-options":{
            enabled: false,
          },"search-options":{
            enabled: true,
            externalQuery: _vm.approvedRegistrantSearchTerm },"pagination-options":{
            enabled: true,
            perPageDropdownEnabled: false,
            dropdownAllowAll: false,
          }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-card',{attrs:{"title":"Baptism Verification List"}},[_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Search")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Search","type":"text"},model:{value:(_vm.baptismVerificationSearchTerm),callback:function ($$v) {_vm.baptismVerificationSearchTerm=$$v},expression:"baptismVerificationSearchTerm"}})],1)])],1),_c('vue-good-table',{attrs:{"columns":_vm.baptismVerificationColumns,"rows":_vm.baptismVerificationRows,"style-class":"vgt-table striped","sort-options":{
            enabled: false,
          },"search-options":{
            enabled: true,
            externalQuery: _vm.baptismVerificationSearchTerm },"pagination-options":{
            enabled: true,
            perPageDropdownEnabled: false,
            dropdownAllowAll: false,
          }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-card',{attrs:{"title":"Successful Baptism Verification List"}},[_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Search")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Search","type":"text"},model:{value:(_vm.baptismSucceededSearchTerm),callback:function ($$v) {_vm.baptismSucceededSearchTerm=$$v},expression:"baptismSucceededSearchTerm"}})],1)])],1),_c('vue-good-table',{attrs:{"columns":_vm.baptismSucceededColumns,"rows":_vm.baptismSucceededRows,"style-class":"vgt-table striped","sort-options":{
            enabled: false,
          },"search-options":{
            enabled: true,
            externalQuery: _vm.baptismSucceededSearchTerm },"pagination-options":{
            enabled: true,
            perPageDropdownEnabled: false,
            dropdownAllowAll: false,
          }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('app-collapse',{attrs:{"accordion":"","type":"margin"}},_vm._l((_vm.approvedMinistryDivisionLists),function(approvedMinistryDivision){return _c('app-collapse-item',{key:approvedMinistryDivision.ministry,attrs:{"title":((approvedMinistryDivision.ministry) + " (" + (approvedMinistryDivision.ministry_count) + ")")}},_vm._l((approvedMinistryDivision.details),function(detail){return _c('div',{key:detail.division},[_c('div',{staticClass:"float-left"},[_vm._v(" "+_vm._s(detail.division)+" ")]),_c('div',{staticClass:"font-weight-bolder float-right"},[_vm._v(" "+_vm._s(detail.division_count)+" ")]),_c('br')])}),0)}),1)],1)],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-card',{staticClass:"text-center"},[_c('b-avatar',{staticClass:"mb-1",attrs:{"variant":"light-success","size":"45"}},[_c('feather-icon',{attrs:{"size":"21","icon":"CheckIcon"}})],1),_c('div',{staticClass:"truncate"},[_c('h2',{staticClass:"mb-25 font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.passedCount)+" ")]),_c('span',[_vm._v("Passed Applicants")])])],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-card',{staticClass:"text-center"},[_c('b-avatar',{staticClass:"mb-1",attrs:{"variant":"light-danger","size":"45"}},[_c('feather-icon',{attrs:{"size":"21","icon":"XIcon"}})],1),_c('div',{staticClass:"truncate"},[_c('h2',{staticClass:"mb-25 font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.failedCount)+" ")]),_c('span',[_vm._v("Failed Applicants")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <loading
      :active="isLoadingRegistrantLists"
      loader="bars"
      color="#7367f0"
      :is-full-page="fullPage"
    />
    
    <b-row>
      <b-col cols="6">
        <b-card :title="`Applicants (${registrantTotal})`">
          <!-- search input -->
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="registrantSearchTerm"
                  placeholder="Search"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>

          <vue-good-table
            :columns="registrantColumns"
            :rows="registrantRows"
            style-class="vgt-table striped"
            :sort-options="{
              enabled: false,
            }"
            :search-options="{
              enabled: true,
              externalQuery: registrantSearchTerm }"
            :pagination-options="{
              enabled: true,
              perPageDropdownEnabled: false,
              dropdownAllowAll: false,
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card :title="`Approved Applicants (${approvedRegistrantTotal})`">
          <!-- search input -->
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="approvedRegistrantSearchTerm"
                  placeholder="Search"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>

          <vue-good-table
            :columns="approvedRegistrantColumns"
            :rows="approvedRegistrantRows"
            style-class="vgt-table striped"
            :sort-options="{
              enabled: false,
            }"
            :search-options="{
              enabled: true,
              externalQuery: approvedRegistrantSearchTerm }"
            :pagination-options="{
              enabled: true,
              perPageDropdownEnabled: false,
              dropdownAllowAll: false,
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-card title="Baptism Verification List">
          <!-- search input -->
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="baptismVerificationSearchTerm"
                  placeholder="Search"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>

          <vue-good-table
            :columns="baptismVerificationColumns"
            :rows="baptismVerificationRows"
            style-class="vgt-table striped"
            :sort-options="{
              enabled: false,
            }"
            :search-options="{
              enabled: true,
              externalQuery: baptismVerificationSearchTerm }"
            :pagination-options="{
              enabled: true,
              perPageDropdownEnabled: false,
              dropdownAllowAll: false,
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card title="Successful Baptism Verification List">
          <!-- search input -->
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="baptismSucceededSearchTerm"
                  placeholder="Search"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>

          <vue-good-table
            :columns="baptismSucceededColumns"
            :rows="baptismSucceededRows"
            style-class="vgt-table striped"
            :sort-options="{
              enabled: false,
            }"
            :search-options="{
              enabled: true,
              externalQuery: baptismSucceededSearchTerm }"
            :pagination-options="{
              enabled: true,
              perPageDropdownEnabled: false,
              dropdownAllowAll: false,
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <app-collapse
          accordion
          type="margin"
        >
          <app-collapse-item
            v-for="approvedMinistryDivision in approvedMinistryDivisionLists"
            :key="approvedMinistryDivision.ministry"
            :title="`${approvedMinistryDivision.ministry} (${approvedMinistryDivision.ministry_count})`"
          >
            <div
              v-for="detail in approvedMinistryDivision.details"
              :key="detail.division"
            >
              <div class="float-left">
                {{ detail.division }}
              </div>
              <div class="font-weight-bolder float-right">
                {{ detail.division_count }}
              </div><br>
            </div>
          </app-collapse-item>
        </app-collapse>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="6">
        <b-card class="text-center">
          <b-avatar
            class="mb-1"
            variant="light-success"
            size="45"
          >
            <feather-icon
              size="21"
              icon="CheckIcon"
            />
          </b-avatar>
          <div class="truncate">
            <h2 class="mb-25 font-weight-bolder">
              {{ passedCount }}
            </h2>
            <span>Passed Applicants</span>
          </div>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card class="text-center">
          <b-avatar
            class="mb-1"
            variant="light-danger"
            size="45"
          >
            <feather-icon
              size="21"
              icon="XIcon"
            />
          </b-avatar>
          <div class="truncate">
            <h2 class="mb-25 font-weight-bolder">
              {{ failedCount }}
            </h2>
            <span>Failed Applicants</span>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BAvatar,
  // BDropdown,
  // BDropdownItem,
} from 'bootstrap-vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { VueGoodTable } from 'vue-good-table'
import _ from 'lodash'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
// import { isUserLoggedIn } from '@/auth/utils'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BCard,
    // BMediaAside,
    // BAvatar,
    BCol,
    BRow,
    Loading,
    VueGoodTable,
    BFormGroup,
    BFormInput,
    BAvatar,
    // BDropdown,
    // BDropdownItem,
  },
  data() {
    return {
      isLoadingRegistrantLists: true,
      fullPage: true,
      pageLength: 10,
      dir: false,
      registrantColumns: [
        {
          label: 'Ministry',
          field: 'name',
        },
        {
          label: 'Count',
          field: 'count',
        },
      ],
      registrantRows: [],
      registrantTotal: 0,
      registrantSearchTerm: '',
      approvedRegistrantColumns: [
        {
          label: 'Ministry',
          field: 'name',
        },
        {
          label: 'Count',
          field: 'count',
        },
      ],
      approvedRegistrantRows: [],
      approvedRegistrantTotal: 0,
      approvedRegistrantSearchTerm: '',
      baptismVerificationColumns: [
        {
          label: 'Ministry',
          field: 'name',
        },
        {
          label: 'Count',
          field: 'count',
        },
      ],
      baptismVerificationRows: [],
      baptismVerificationSearchTerm: '',
      baptismSucceededColumns: [
        {
          label: 'Ministry',
          field: 'name',
        },
        {
          label: 'Count',
          field: 'count',
        },
      ],
      baptismSucceededRows: [],
      baptismSucceededSearchTerm: '',
      approvedMinistryDivisionLists: [],
      passedCount: 0,
      failedCount: 0,
    }
  },
  async mounted() {
    // Get the registrant data
    const registrantData = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/statistic/registrant/${this.$route.params.id}`)
    this.registrantRows = registrantData.data
    this.registrantTotal = _.sumBy(this.registrantRows, o => Number(o.count))

    // Get the approved registrant data
    const approvedRegistrantData = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/statistic/approved/registrant/${this.$route.params.id}`)
    this.approvedRegistrantRows = approvedRegistrantData.data
    this.approvedRegistrantTotal = _.sumBy(this.approvedRegistrantRows, o => Number(o.count))

    // Get the Baptism Verification lists
    const baptismVerificationList = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/statistic/baptism/verification/${this.$route.params.id}`)
    this.baptismVerificationRows = baptismVerificationList.data

    // Get the Baptism succeeded lists
    const baptismSucceededList = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/statistic/baptism/success/${this.$route.params.id}`)
    this.baptismSucceededRows = baptismSucceededList.data

    // Get the Baptism succeeded lists
    const ministryDivisionLists = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/statistic/ministry/division/${this.$route.params.id}`)
    this.approvedMinistryDivisionLists = _.chain(ministryDivisionLists.data).groupBy('ministry').map((val, key) => ({ ministry: key, ministry_count: _.sumBy(val, o => Number(o.ministry_count)), details: val })).value()

    // Get passed count
    const passedApplicants = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/statistic/pass/${this.$route.params.id}`)
    this.passedCount = !_.isEmpty(passedApplicants.data) ? passedApplicants.data[0].count : 0

    // Get failed count
    const failedApplicants = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/statistic/fail/${this.$route.params.id}`)
    this.failedCount = !_.isEmpty(failedApplicants.data) ? failedApplicants.data[0].count : 0

    // Hide loading
    this.isLoadingRegistrantLists = false
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
table.vgt-table {
  border: none;
  font-size: 1rem;
}
.vgt-table thead th {
  // border-bottom: 2px solid #ebe9f1;
  outline: none;
  background-color: #f3f2f7;
}
table.vgt-table td {
  vertical-align: middle !important;
}
.vgt-wrap__footer {
  border: none;
}
</style>